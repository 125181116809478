import { pickBy, identity } from 'lodash';
import http from '@/services/http-client';
import routes from '@/../config/env';

const { cronos } = routes;

export const bookEvent = (eventId, pricingMode) => http({
  method: 'post',
  url: `${cronos}/events/book`,
  data: pickBy({
    eventId,
    pricingMode,
  }, identity),
});

export const bookAllFeaturesEvent = (eventId, pricingMode) => http({
  method: 'post',
  url: `${cronos}/events/all/book`,
  data: pickBy({
    eventId,
    pricingMode,
  }, identity),
});

export const unbookEvent = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/un-book`,
  data: {
    eventId,
  },
});

export const isBooked = (eventId) => http({
  method: 'get',
  url: `${cronos}/events/${eventId}`,
});

export const bookSameGameParlays = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/sgp/book`,
  data: {
    eventId,
  },
});

export const unbookSameGameParlays = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/sgp/un-book`,
  data: {
    eventId,
  },
});

export const isSameGameParlaysBooked = (eventId) => http({
  method: 'get',
  url: `${cronos}/events/sgp/${eventId}`,
});

export const bookPlayerProps = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/player-props/book`,
  data: {
    eventId,
  },
});

export const unbookPlayerProps = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/player-props/un-book`,
  data: {
    eventId,
  },
});

export const isPlayerPropsBooked = (eventId) => http({
  method: 'get',
  url: `${cronos}/events/player-props/${eventId}`,
});

export const bookMicroMarkets = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/micro/book`,
  data: {
    eventId,
  },
});

export const unbookMicroMarkets = (eventId) => http({
  method: 'post',
  url: `${cronos}/events/micro/un-book`,
  data: {
    eventId,
  },
});

export const isMicroMarketsBooked = (eventId) => http({
  method: 'get',
  url: `${cronos}/events/micro/${eventId}`,
});

export const subscribe = (competitionId, pricingMode) => http({
  method: 'post',
  url: `${cronos}/subscriptions/subscribe`,
  data: pickBy({
    id: competitionId,
    pricingMode,
  }, identity),
});

export const unsubscribe = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/un-subscribe`,
  data: {
    id: competitionId,
  },
});

export const isSubscribed = (competitionId) => http({
  method: 'get',
  url: `${cronos}/subscriptions/${competitionId}`,
});

export const subscribeSameGameParlays = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/sgp/subscribe`,
  data: {
    id: competitionId,
  },
});

export const unsubscribeSameGameParlays = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/sgp/un-subscribe`,
  data: {
    id: competitionId,
  },
});

export const isSameGameParlaysSubscribed = (competitionId) => http({
  method: 'get',
  url: `${cronos}/subscriptions/sgp/${competitionId}`,
});

export const subscribePlayerProps = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/player-props/subscribe`,
  data: {
    id: competitionId,
  },
});

export const unsubscribePlayerProps = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/player-props/un-subscribe`,
  data: {
    id: competitionId,
  },
});

export const isPlayerPropsSubscribed = (competitionId) => http({
  method: 'get',
  url: `${cronos}/subscriptions/player-props/${competitionId}`,
});

export const subscribeMicroMarkets = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/micro/subscribe`,
  data: {
    id: competitionId,
  },
});

export const unsubscribeMicroMarkets = (competitionId) => http({
  method: 'post',
  url: `${cronos}/subscriptions/micro/un-subscribe`,
  data: {
    id: competitionId,
  },
});

export const isMicroMarketsSubscribed = (competitionId) => http({
  method: 'get',
  url: `${cronos}/subscriptions/micro/${competitionId}`,
});
